import { CircularProgress } from "@mui/material";
import React from "react";
import "./loading-spinner.css";

const SpinnerLoading = () => {
    return (
        <div className="container-loading">
            <CircularProgress style={{color: '#f04243'}} size="3rem"/>
        </div>
    );
};

export default SpinnerLoading;
