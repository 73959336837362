import { Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import SpinnerLoading from "./components/loading-spinner/loading-spinner.js";


const Header = lazy(() => import("./components/header/header"));
const Clientes = lazy(() => import("./components/pages/clientes/clientes.js"));
const HomeRedFooter = lazy(() => import("./components/home-red-footer/home-red-footer"));
const WhatsappButton = lazy(() => import("./components/whatsapp-button/whatsapp-button"));
const Servicios = lazy(() => import("./components/pages/servicios/servicios"));
const Home = lazy(() => import("./components/pages/home/home"));
const ProteccionContraIncendios = lazy(() => import("./components/pages/01-proteccion-contra-incendios/01-proteccion-contra-incendios.js"));
const ObrasHidraulicasInfraestructura = lazy(() => import("./components/pages/02-obras-hidraulicas-e-infraestructura/02-obras-hidraulicas-e-infraestructura.js"));
const PuertasMuroCortaFuego = lazy(() => import("./components/pages/03-puertas-muros-cortafuego/03-puertas-muros-cortafuego.js"));
const MantenimientosContraIncendios = lazy(() => import("./components/pages/04-mantenimientos-sistema-contra-incendios/04-mantenimientos-sistema-contra-incendios.js"));
const AutoProteccionContraIncendios = lazy(() => import("./components/pages/05-autoproteccion-contra-incendios/05-autoproteccion-contra-incendios.js"));
const ProyectosIntegralesPlanificacion = lazy(() => import("./components/pages/06-proyectos-integrales-de-planificacion-e-ingenieria/06-proyectos-integrales-de-planificacion-e-ingenieria.js"));
const AsesoramientoHigieneSeguridad = lazy(() => import("./components/pages/07-asesoramiento-en-higiene-y-seguridad/07-asesoramiento-en-higiene-y-seguridad.js"));
const TanquesBombasIncendio = lazy(() => import("./components/pages/08-tanques-y-bombas-de-incendio/08-tanques-y-bombas-de-incendio.js"));
const Proyecto1 = lazy(() => import("./components/pages/proyecto-1/proyecto-1.js"));
const Proyecto2 = lazy(() => import("./components/pages/proyecto-2/proyecto-2.js"));
const Proyecto3 = lazy(() => import("./components/pages/proyecto-3/proyecto-3.js"));

export const RouterApp = () => {
  return (
    <Suspense fallback={<SpinnerLoading/>}>
      <Header />
      <WhatsappButton />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/clientes" element={<Clientes />} />
        <Route path="/01-proteccion-contra-incendios/" element={<ProteccionContraIncendios />} />
        <Route path="/02-obras-hidraulicas-e-infraestructura/" element={<ObrasHidraulicasInfraestructura />} />
        <Route path="/03-puertas-muros-cortafuego-y-caja-de-escalera-presurizada/" element={<PuertasMuroCortaFuego />} />
        <Route path="/04-mantenimientos-sistema-contra-incendios/" element={<MantenimientosContraIncendios />} />
        <Route path="/05-autoproteccion-contra-incendios/" element={<AutoProteccionContraIncendios />} />
        <Route path="/06-proyectos-integrales-de-planificacion-e-ingenieria/" element={<ProyectosIntegralesPlanificacion />} />
        <Route path="/07-asesoramiento-en-higiene-y-seguridad/" element={<AsesoramientoHigieneSeguridad />} />
        <Route path="/08-tanques-y-bombas-de-incendio/" element={<TanquesBombasIncendio />} />
        <Route path="/proyecto-1/" element={<Proyecto1 />} />
        <Route path="/proyecto-2/" element={<Proyecto2 />} />
        <Route path="/proyecto-3/" element={<Proyecto3 />} />
      </Routes>
      <HomeRedFooter />
    </Suspense>
  );
};
